import React, {useState} from 'react'
import {useResponsive} from 'src/hooks/use-responsive'
import {ModalNotifications} from './ModalNotifications'
import {Badge, badgeClasses, IconButton} from '@mui/material'
import DrawerNotifications from './DrawerNotifications'
import {useEffect} from 'react';
import {notificationsApi} from 'src/store/notifications/notificationsApi';
import {useNotificationsFilters} from 'src/hooks/use-notifications-filters';
import CustomDateRangePicker from '../custom-date-range-picker/custom-date-range-picker';
import {ReactComponent as NotificationsIcon} from 'src/assets/icons/notifications-24.svg';
import {useSelector} from 'react-redux'

export function Notifications() {
  const [triggerRead] = notificationsApi.useLazyReadQuery()

  const {
    tab,
    onChangeTabs,
    appliedFilters,
    onDeleteAppliedFilters,
    updateRangeDate,
    updateStatusFilter,
    updateDateFilter,
    onApplyFilters,
    onResetDateRange,
    onApplyDateRange,
    onResetEmpty,
    onResetFilters,
    onChangeText,
    onClearText,
    statusFilter,
    dateFilter,
    rangeDateLabel,
    rangeDate,
    fetchNotificationsQuery,
    typeFilter,
    updateTypeFilter,
    onReload,
    textFilter
  } = useNotificationsFilters()

  const smUp = useResponsive('up', 'sm');
  const smDown = useResponsive('down', 'sm');

  const {notificationsCount} = useSelector(store => store.notifications)

  const [selectedNotification, setSelectedNotification] = useState("")
  const [filtersView, setFiltersView] = useState(false)
  const [dateModal, setDateModal] = useState(false)
  const [notificationsContent, setNotificationsContent] = useState(false)

  const showDateModal = () => setDateModal(true)
  const hideDateModal = () => setDateModal(false)

  const resetNotificationsLayout = () => {
    setSelectedNotification('')
    setFiltersView(false)
  }

  const showNotificationsContent = () => {
    onReload()
    setNotificationsContent(true)
  }
  const hideNotificationsContent = () => {
    setNotificationsContent(false)
    resetNotificationsLayout()
    onResetEmpty()
  }

  const onApplyDateRangeModal = () => {
    onApplyDateRange()
    hideDateModal()
  }

  const onResetDateRangeModal = () => {
    onResetDateRange()
    hideDateModal()
  }

  const notifications = fetchNotificationsQuery?.data?.notifications

  const unreadIds = notifications?.filter((notification) => notification?.status === 'new').map(({id}) => id)

  const read = async (notification) => {
    const id = notification?.id
    try {
      if (notification.status === 'new') {
        await triggerRead({ids: [id]})
      }
    } catch (e) {
      console.log(e)
    }
  }

  const readAll = async () => {
    try {
      await triggerRead({ids: [unreadIds]})
      onReload()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (selectedNotification) {
      read(selectedNotification)
    }
  }, [selectedNotification])

  const showFilters = () => {
    setFiltersView(true)
  }

  const notificationsProps = {
    tab,
    onClearText,
    onChangeTabs,
    onChangeText,
    textFilter,
    selectedNotification,
    setSelectedNotification,
    notifications,
    resetNotificationsLayout,
    filtersView,
    dateFilter,
    statusFilter,
    onResetFilters,
    updateStatusFilter,
    updateDateFilter,
    onApplyFilters,
    showDateModal,
    rangeDateLabel,
    showFilters,
    typeFilter,
    updateTypeFilter,
    appliedFilters,
    onDeleteAppliedFilters,
    loading: fetchNotificationsQuery?.isFetching,
    onResetEmpty,
    readAll,
    unreadIds
  }

  return (
    <>
      <CustomDateRangePicker
        variant='calendar'
        open={dateModal}
        onClose={hideDateModal}
        title={rangeDateLabel || "За какой период?"}
        rangeDate={rangeDate}
        updateRangeDate={updateRangeDate}
        onApplyDateRange={onApplyDateRangeModal}
        onResetDateRange={onResetDateRangeModal}
      />

      {!!notificationsContent && smDown &&
        <ModalNotifications
          open={notificationsContent}
          hideModal={hideNotificationsContent}
          {...notificationsProps}
        />
      }
      {smUp &&
        <DrawerNotifications
          open={notificationsContent}
          onClose={hideNotificationsContent}
          {...notificationsProps}
        />
      }
      <IconButton
        onClick={showNotificationsContent}
      >
        <Badge badgeContent={notificationsCount} color="error" sx={{
          [`& .${badgeClasses.badge}`]: {
            right: -1,
            height: 18
          },
        }}>
          <NotificationsIcon sx={{fill: "#212B36"}} />
        </Badge>
      </IconButton>
    </>
  )
}
