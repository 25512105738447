import Box from '@mui/material/Box'
import React, {useEffect, useState} from 'react'
import {Snackbar, Typography} from '@mui/material'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import {useSelector} from 'react-redux'
import {dispatch} from '../store/store'
import {notificationsSlice} from 'src/store/notifications/notificationsSlice'

export default function NotificationMessages() {
  const notifications = useSelector(store => store.notifications.notificationMessages)
  const {user} = useSelector(store => store.user)
  const [autoClosed, setAutoClosed] = useState([])

  const handleClose = (message) => {
    dispatch(notificationsSlice.actions.removeNotificationMessages(message))
  }

  useEffect(() => {
    notifications.forEach(message => {
      if (message.autoClose && !autoClosed.includes(message.key)) {
        setAutoClosed([...autoClosed, message.key])
        setTimeout(() => dispatch(notificationsSlice.actions.removeNotificationMessages(message)), 5e3)
      }
    })
  }, [notifications, autoClosed])

  useEffect(() => {
    if (process.env.REACT_APP_NOTIFICATIONS_TIMEOUT) {
      const fetch = () => {
        dispatch(notificationsSlice.actions.fetchMessages())
      };

      const intervalId = setInterval(fetch, process.env.REACT_APP_NOTIFICATIONS_TIMEOUT);

      if (user) {
        fetch();
      } else {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }
  }, [user]);

  return (
    <Snackbar
      open={notifications.length > 0}
      onClose={handleClose}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
    >
      <Box sx={{width: '100%'}}>
        <Stack spacing={2} direction="column" sx={{width: '100%'}}>
          {notifications.map(message => {
            return (
              <Alert
                key={message.key}
                onClose={() => handleClose(message)}
                severity='info'
                sx={{width: '100%'}}
              >
                <Typography variant='subtitle1' sx={{color: '#212B36'}}>
                  {message.title}
                </Typography>
                <Typography variant='body2' sx={{color: '#454F5B'}}>
                  {message.text}
                </Typography>
              </Alert>
            )
          })}
        </Stack>
      </Box>
    </Snackbar>
  )
}

export {NotificationMessages}
