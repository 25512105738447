import {call, put, takeLatest} from "redux-saga/effects"
import {notificationsSlice} from './notificationsSlice'
import {network} from '../../utils/network'

function* markView(payload) {
  try {
    yield network.axios({
      method: 'POST',
      url: '/notification/mark_viewed/',
      data: {
        ids: payload.ids
      }
    })

  } catch (e) {
    console.log('e', e)
  } finally {
  }
}


function* fetch({payload}) {
  yield put(notificationsSlice.actions.setLoading({type: 'notifications', loading: true}))

  try {
    const response = yield network.axios({
      method: 'GET',
      url: '/notification/',
      params: {
        status: payload.status,
        notification_type: payload.notification_type,
        text: payload.text,
        date_from: payload.date_from,
        date_to: payload.date_to,
      }
    })

    yield put(notificationsSlice.actions.setNotifications({
      data: response.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(notificationsSlice.actions.setLoading({type: 'notifications', loading: false}))
  }
}

function* fetchMessages() {
  try {
    const response = yield network.axios({
      method: 'GET',
      url: '/notification/',
      params: {
        status: 'new',
      }
    })

    const notifications = response.data.notifications
    const notViewed = notifications.filter(item => !item.is_viewed)
    const notViewedIds = notViewed.map(({id}) => id)

    for (const item of notViewed) {
      yield put(notificationsSlice.actions.addNotificationMessages({
        text: item.short_message,
        title: item.title,
      }))
    }


    yield put(notificationsSlice.actions.setNotificationsCount(notifications?.length))
    yield call(markView, {ids: notViewedIds})

  } catch (e) {
    console.log('e', e)
  } finally {
  }
}

function* fetchId({payload}) {
  yield put(notificationsSlice.actions.setLoading({type: 'selectedNotification', loading: true}))
  yield put(notificationsSlice.actions.setSelectedNotification({data: null}))

  try {
    const response = yield network.axios({
      method: 'GET',
      url: `/notifications/${payload.id}/`,
    })

    yield put(notificationsSlice.actions.setSelectedNotification({
      data: response?.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(notificationsSlice.actions.setLoading({type: 'selectedNotification', loading: false}))
  }
}


export function* notificationsSagas() {
  yield takeLatest(notificationsSlice.actions.fetch.type, fetch)
  yield takeLatest(notificationsSlice.actions.fetchMessages.type, fetchMessages)
  yield takeLatest(notificationsSlice.actions.fetchId.type, fetchId)
  yield takeLatest(notificationsSlice.actions.markView.type, markView)
}
